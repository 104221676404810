import React, { useRef } from "react";
import { Container } from "react-bootstrap";
import "../styles/q&a.scss";
import { Link } from "gatsby";
import { AiOutlineArrowDown } from "react-icons/ai";
import MainLayout from "../components/MainLayout";

interface QNAProps {}

const faqs = [
  {
    question: "Where to get started?",
    answer:
      "Start with simple 3D renders or configurators on your website — simple as that. When you are ready to lead your store into the feature, transition to virtual experiance zones.",
    image: "./images/q&n/q1.png",
  },
  {
    question: "How easy to get started?",
    answer:
      "Our platform HelloAR handles everything: server, software, UI. For you it’s nothing more than drag and drop!",
    image: "./images/q&n/q1.png",
  },
  {
    question: "Another question for test?",
    answer:
      "Start with simple 3D renders or configurators on your website — simple as that. When you are ready to lead your store into the feature, transition to virtual experiance zones.",
    image: "./images/q&n/q1.png",
  },
];

const questions = [
  {
    question: "How much will it cost?",
    answer:
      "Our pricing is very transparent and dependent on how many products you use. No hiden costs or fees. Contact our team to get an estimate for your business!",
  },
  {
    question: "How long does it take?",
    answer:
      "Get started in minutes with our simple solutions. Set up experience zones in a matter of weeks with the support team!",
  },
];
const QNA = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <MainLayout width={85} hideFooter>
      <div className="pointer-events-all">
        <div
          id="q&a"
          ref={containerRef}
          className="qna-content-wrapper bg-white"
        >
          <Container className="qna-content">
            <h1 className="mb-4">Still have questions?</h1>
            <div className="faq-container">
              {faqs.map((faq, index) => (
                <div key={index} className="faq-item d-flex">
                  <div className="faq-count">{index + 1}</div>
                  <div className="faq-content">
                    <h6>{faq.question}</h6>
                    <p>{faq.answer}</p>
                    <img
                      className="mt-auto"
                      src={faq.image}
                      alt={faq.question}
                    />
                  </div>
                </div>
              ))}
            </div>
            <div className="qna-bottom-container d-flex">
              {questions.map((question) => (
                <div className="qna-question-item">
                  <h1>{question.question}</h1>
                  <hr className="w-50 ml-0" />
                  <p>{question.answer}</p>
                </div>
              ))}
              <div className="d-grid" style={{ width: "max-content" }}>
                <Link to="/case-studies">
                  <button className="d-block flex-grow-1 button-secondary mb-2">
                    Show Case Studies
                  </button>
                </Link>
                <Link to="/contact-us">
                  <button className="d-block flex-grow-1 button-primary w-100">
                    Book Demo call
                  </button>
                </Link>
              </div>
            </div>
          </Container>
        </div>
        {/* <Link to="/case-studies">
          <button
            className="w-100 button-primary text-dark d-flex align-items-center justify-content-center"
            style={{
              position: "sticky",
              bottom: "0",
              left: "0",
              background: "#F1F4F8",
            }}
          >
            <p className="m-0 mr-2">Next Feature</p>
            <AiOutlineArrowDown />
          </button>
        </Link> */}
      </div>
    </MainLayout>
  );
};

QNA.Layout = MainLayout;

export default QNA;
